$(document).ready(function() {
	var keybuffer = [];
  var reading = false;

  function displayFlash(message, type) {
    $('.alert-success').removeClass('alert');
    $('.alert-success').removeClass('alert-success');
    $('.alert-danger').removeClass('alert');
    $('.alert-danger').removeClass('alert-danger');
    $('.flash-msg').html('<div class="alert alert-' + type +'">' + message + '</div>');
    $("html, body").animate({ scrollTop: 0 }, "fast");
  }

  function handleResponse(response) {
    if (response.error && response.error.length > 0) {
      displayFlash(response.error, "danger");
    } else if (response.message && response.message.length > 0){
      displayFlash(response.message, "success");
    } else {
      $('.flash-msg').html('');
    }
    
    if (response.student_id) {
      var studentRow = $(".student-" + response.student_id);
      studentRow.addClass('alert');
      studentRow.addClass('alert-success');

      if (response.attendance_date && response.attendance_date.length > 0) {
        studentRow.find('.attendance-date').html(response.attendance_date);
      }
      $("html, body").animate({ scrollTop: studentRow.offset().top }, "fast");

    }

    if (response.attendance_id) {
      var attendanceRow = $(".attendance-" + response.attendance_id);
      if (attendanceRow && attendanceRow.length > 0) {
        attendanceRow.addClass('alert');
        attendanceRow.addClass('alert-success');

        if (!response.student_id) {
          $("html, body").animate({ scrollTop: attendanceRow.offset().top }, "fast");
        }
      }
      
    }

    if (response.url && response.url.length > 0) {
      window.location = response.url;
    }
  }

	function scanId(scanned_id){
		scanned_id = scanned_id.replace('\r', '');
		if ($('body.attendances').length) {
			Rails.ajax({
				type: "POST", 
				url: "/attendances/scan",
				data: 'student_id=' + scanned_id,
				contentType: 'json',
				success: function(data) { 
					var response = JSON.parse(data);
					if(response != null){
            handleResponse(response);
					}
				},
				error: function(data) {
          displayFlash("Unable to capture attendance", "danger");
				}
			})
    } else if ($('body.event_attendances').length) {
			Rails.ajax({
				type: "POST", 
				url: "/event_attendances/scan",
				data: 'student_id=' + scanned_id,
				contentType: 'json',
				error: function(data) {
          displayFlash("Unable to capture attendance", "danger");
				}
			})
    } else if ($('body.time_entries').length) {
			Rails.ajax({
				type: "POST", 
				url: "/time_entries/scan",
				data: 'teacher_id=' + scanned_id,
				contentType: 'json',
				success: function(data) { 
					var response = JSON.parse(data);
					if(response != null){
            handleResponse(response);
					}
				},
				error: function(data) {
          displayFlash("Unable to capture time entry", "danger");
				}
			})
		} else if ($('body.lunch_orders').length) {
			Rails.ajax({
				type: "POST", 
				url: "/lunch_orders/scan",
				data: 'student_id=' + scanned_id,
				contentType: 'json',
				success: function(data) { 
					var response = JSON.parse(data);
					if(response != null){
						if (response.error && response.error.length > 0) {
              displayFlash(response.error, "danger");
						} 

            if (response.url && response.url.length > 0) {
						  window.location = response.url; 
            }
					} else {
							console.log(response);
					}
				},
				error: function(data) {
          displayFlash("Unable to retrieve lunch order", "danger");
				}
			})
			
		} else if ($('body.sessions').length === 0 && $('body.new').length === 0 && $('body.edit').length === 0) {
			Rails.ajax({
				type: "GET", 
				url: "/scan",
				data: 'student_id=' + scanned_id,
				contentType: 'json',
				success: function(data) { 
					var response = JSON.parse(data);
					if(response != null){
							window.location = '/students/' + response.id; 
					} 
				},
				error: function(data) {
					displayFlash("An error occurred.", "danger");
				}
			})
		} 
	};

	function press(event) {
		if (event.which === 13) {
			return send();
		}
		var number = event.which - 48;
		if (number < 0 || number > 9) {
			return;
		}
		keybuffer.push(number);

	  if(!reading){
		  reading=true;
		  setTimeout(()=>{
				keybuffer=[];
				reading=false;
		  }, 200);
    }
	}

	$(document).on("keypress", press);

	function send() {
    var studentID
		scanId(keybuffer.join(""));
		keybuffer.length = 0;
	}

});
